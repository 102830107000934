//使用mockjs模拟数据
//data里的属性看不懂，需要详细看语法规范,博客链接:https://www.jianshu.com/p/4579f40e6108

//引入mockjs
import Mock from 'mockjs'
const ApiUrl=process.env.VUE_APP_ApiUrl||'';

Mock.mock('/api/upLoadFile', {//上传
  "status":1,
  "data":"@cname",
  "errmsg":"成功"

});

Mock.mock(ApiUrl+'/api/testLogin', {//获取登录信息
    "status":1,
    "data":"@cname",
    "errmsg":"成功",
});
Mock.mock(ApiUrl+'/api/getUserInfo', {//获取用户权限
  "status":1,
  "data":{
    roles:["super"]
  },
  "errmsg":"成功"
});
Mock.mock(ApiUrl+'/api/updataPassword', {//修改密码
  "status":1,
  "data":'',
  "errmsg":"成功"
});



Mock.setup({
  timeout: '200-600'
})
