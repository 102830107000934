
import {get,post} from "@/axios";

//用户页面
export function getUserList(data) {
    return post('/api/users/list',data);
}
export function addUser(data) {
    return post('/api/users/add',data);
}
export function editUser(data) {
    console.log(data,'接收到的数据')
    return post('/api/users/edit',data);
}

export function changePassword(data) {
    return post('/api/users/modify_password',data);
}

export function getAllUserList(data) {
    return get('/api/users/allUserList',data);
}